import React from 'react'
import { useTranslation } from 'react-i18next'

export const SuccessScreen = () => {
  const { t } = useTranslation('common')

  return (
    <div className='success flex-container column text-center justify-center align-center'>
      <h1 >{`${t('success-screen.title')}`}</h1>
      <p>{`${t('success-screen.description')}`}</p>
      <a className="goApp" href="https://ibions365.whitekongsoftware.com/login">{t('goApp.description')}</a>
    </div>
  )
}
