import './App.css';
import { AppRoutes } from './routes/AppRoutes';

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
