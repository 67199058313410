import React from 'react'
import { useTranslation } from 'react-i18next'

export const RedirectApp = ({ code }) => {

  const { t } = useTranslation('common')

  return (
    <>
      <div className='flex-container column justify-center align-center text-center'>
        <p>{t('confirmAccount.description')}</p>
      <a className='goApp' href="https://ibions365.whitekongsoftware.com/login">{t('goApp.description')}</a>
      </div>
    </>
  )
}
